import React, { Suspense, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Spinner } from "react-bootstrap";
import { ServerURL } from "../../urls";
import filterIcon from "../../images/filter.png";
import aiMlIcon from "../../images/ai-ml.svg";
import cloudIcon from "../../images/cloud.svg";
import dataIcon from "../../images/data.svg";
import industriesIcon from "../../images/industries.svg";
import othersIcon from "../../images/others.svg";
import productIcon from "../../images/product.svg";
import trendingIcon from "../../images/trending.svg";
import qs from "qs";
import axios from "axios";
import { Form as BootstrapForm } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import LetsTalk from "../Home/LetsTalk";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import ConversationSection from "../Contactus/ConversationSection";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const categories = [
  {
    value: "AI/ML",
    categoriesItem: "AI/ML",
    id: "65c6044d4aa574b9df17b5aa",
    subcategories: [
      {
        value: "Model Development & Deployment",
        label: "Model Development & Deployment",
      },
      { value: "Generative AI", label: "Generative AI" },
      { value: "AI For Automation", label: "AI For Automation" },
    ],
  },
  {
    value: "Data",
    categoriesItem: "Data",
    id: "65c604534aa574b9df17b5ab",
    subcategories: [
      { value: "Data Engineering", label: "Data Engineering" },
      { value: "Data Preparation", label: "Data Preparation" },
      { value: "Real-Time Analytics", label: "Real-Time Analytics" },
      { value: "Big Data Solutions", label: "Big Data Solutions" },
    ],
  },
  {
    value: "Cloud",
    categoriesItem: "Cloud",
    id: "65c604564aa574b9df17b5ac",
    subcategories: [
      { value: "Cloud Enablement", label: "Cloud Enablement" },
      { value: "Cloud Migration", label: "Cloud Migration" },
      { value: "DevOps On Cloud", label: "DevOps On Cloud" },
      {
        value: "Cloud Native App Development",
        label: "Cloud Native App Development",
      },
      { value: "Real Time Analytics", label: "Real Time Analytics" },
      { value: "Managed Services", label: "Managed Services" },
    ],
  },
  {
    value: "Product",
    categoriesItem: "Product",
    id: "65c6045b4aa574b9df17b5ad",
    subcategories: [
      { value: "Product Engineering", label: "Product Engineering" },
      { value: "Enterprise Applications", label: "Enterprise Applications" },
      { value: "Web Applications", label: "Web Applications" },
      { value: "Mobility", label: "Mobility" },
      { value: "QA Services", label: "QA Services" },
    ],
  },
  {
    value: "Industries",
    categoriesItem: "Industries",
    id: "65c6045b4aa574b9df17b5ad",
    subcategories: [
      { value: "Healthcare", label: "Healthcare" },
      { value: "Finance", label: "Finance" },
      { value: "Energy", label: "Energy" },
      { value: "Retail", label: "Retail" },
    ],
  },
  {
    value: "Trending",
    categoriesItem: "Trending",
    id: "65c6045b4aa574b9df17b5ad",
  },
  {
    value: "Others",
    categoriesItem: "Others",
    id: "65c6045b4aa574b9df17b5ad",
  },
];

const Blogs = () => {
  const [letsTalkVisible, setLetsTalkVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchPending, setIsSearchPending] = useState(false);
  const [isInitialPageRender, setIsInitialPageRender] = useState(false);
  const [isFilterListOpen, setIsFilterListOpen] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("Categories"); // Default is "All"
  const [categorisedData, setCategorisedData] = useState([]);
  const scrollToRef = useRef(null);
  // API data state
  const [blogsMainNew, setBlogsMainNew] = useState([]);
  // Pagination start
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  let records = [];
  let nPage = 0;
  if (categorisedData.length !== 0) {
    records = categorisedData.slice(firstIndex, lastIndex);
    nPage = Math.ceil(categorisedData.length / recordsPerPage);
  } else {
    records = blogsMainNew.slice(firstIndex, lastIndex);
    nPage = Math.ceil(blogsMainNew.length / recordsPerPage);
  }
  const [blogsList, setBlogsList] = useState([]);
  const [prerenderBlogsList, setPrerenderBlogsList] = useState([]);
  const [blogsData, setBlogsData] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [checkedCategories, setCheckedCategories] = useState([]);
  const [checkedSubCategories, setCheckedSubCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [triggerSearch, setTriggerSearch] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [expandedCategories, setExpandedCategories] = useState([]);

  const navigate = useNavigate();


  const prePage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
    setTriggerSearch(isSearching);
    scrollToBlogListSection();
  };

  const nextPage = () => {
    if (page < totalPage) {
      setPage(page + 1);
    }
    setTriggerSearch(isSearching);
    scrollToBlogListSection();
  };

  useEffect(() => {
    const hash = window.location.hash.replace("#", "");
    const pageFromHash = parseInt(hash, 10);
    if (!isNaN(pageFromHash) && pageFromHash > 0 && pageFromHash <= totalPage) {
      setPage(pageFromHash);
    }
  }, [window.location.hash, totalPage]);

  const changeCurrentPage = (pageNumber) => {
    setPage(pageNumber);
    navigate(`#${pageNumber}`);
    scrollToBlogListSection();
  };

  // Calculate visible pages
  const renderPagination = () => {
    const pages = [];
    const maxPagesToShow = 3;

    if (totalPage <= maxPagesToShow + 2) {
      for (let i = 1; i <= totalPage; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);

      let startPage = Math.max(2, page - 1);
      let endPage = Math.min(totalPage - 1, page + 1);

      if (page === 1) {
        endPage = Math.min(totalPage - 1, 3);
      } else if (page === totalPage) {
        startPage = Math.max(2, totalPage - 2);
      }

      if (startPage > 2) {
        pages.push("...");
      }

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      if (endPage < totalPage - 1) {
        pages.push("...");
      }

      pages.push(totalPage);
    }

    return pages;
  };

  const handleLetsTalk = () => {
    setLetsTalkVisible(true);
  };
  const handleCancel = () => {
    setLetsTalkVisible(false);
  };

  const toggleFilterList = () => {
    setIsFilterListOpen(!isFilterListOpen);
  };

  const scrollToBlogListSection = () => {
    if (scrollToRef.current) {
      window.scrollTo({
        top: scrollToRef.current.offsetTop - 120,
        behavior: "smooth", // You can change this to 'auto' for instant scroll
      });
    }
  };

  const handleCheckboxChange = (
    value,
    isChecked,
    parentCategory = null,
    subCategory = null
  ) => {
    let updatedCheckedCategories = [...checkedCategories];
    let updatedCheckedSubCategories = [...checkedSubCategories];

    if (subCategory) {
      if (isChecked) {
        updatedCheckedSubCategories.push(value);
        if (parentCategory) {
          updatedCheckedCategories = updatedCheckedCategories.filter(
            (item) => item !== parentCategory
          );
        }
      } else {
        updatedCheckedSubCategories = updatedCheckedSubCategories.filter(
          (item) => item !== value
        );
        const category = categories.find((cat) => cat.value === parentCategory);
        if (
          category &&
          category.subcategories &&
          category.subcategories.length > 0
        ) {
          const allSubcategoriesUnchecked = category.subcategories.every(
            (subCat) => !updatedCheckedSubCategories.includes(subCat.value)
          );
          if (allSubcategoriesUnchecked) {
            updatedCheckedCategories = updatedCheckedCategories.filter(
              (item) => item !== parentCategory
            );
          }
        }
      }
    } else {
      if (isChecked) {
        updatedCheckedCategories.push(value);
        const category = categories.find((cat) => cat.value === value);
        if (category && category.subcategories) {
          category.subcategories.forEach((subCat) => {
            if (!updatedCheckedSubCategories.includes(subCat.value)) {
              updatedCheckedSubCategories.push(subCat.value);
            }
          });
        }
      } else {
        updatedCheckedCategories = updatedCheckedCategories.filter(
          (item) => item !== value
        );
        const category = categories.find((cat) => cat.value === value);
        if (category && category.subcategories) {
          category.subcategories.forEach((subCat) => {
            updatedCheckedSubCategories = updatedCheckedSubCategories.filter(
              (item) => item !== subCat.value
            );
          });
        }
      }
    }

    if (parentCategory) {
      const category = categories.find((cat) => cat.value === parentCategory);
      if (
        category &&
        category.subcategories &&
        category.subcategories.length > 0
      ) {
        const allSubcategoriesChecked = category.subcategories.every((subCat) =>
          updatedCheckedSubCategories.includes(subCat.value)
        );
        if (allSubcategoriesChecked) {
          if (!updatedCheckedCategories.includes(parentCategory)) {
            updatedCheckedCategories.push(parentCategory);
          }
        } else {
          updatedCheckedCategories = updatedCheckedCategories.filter(
            (item) => item !== parentCategory
          );
        }
      }
    }

    setCheckedCategories(updatedCheckedCategories);
    setCheckedSubCategories(updatedCheckedSubCategories);
    setFilterValue(
      [...updatedCheckedCategories, ...updatedCheckedSubCategories].join(" ")
    );
  };

  const toggleSubcategories = (categoryValue) => {
    if (expandedCategories.includes(categoryValue)) {
      setExpandedCategories(
        expandedCategories.filter((cat) => cat !== categoryValue)
      );
    } else {
      setExpandedCategories([...expandedCategories, categoryValue]);
    }
  };

  const isCategoryExpanded = (categoryValue) => {
    return expandedCategories.includes(categoryValue);
  };

  const handleSubcategoryClick = (subcategory, parentCategory) => {
    const isChecked = !checkedSubCategories.includes(subcategory.value);
    handleCheckboxChange(
      subcategory.value,
      isChecked,
      parentCategory,
      subcategory.value
    );
  };

  // Debounce logic
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsTyping(false);
    }, 500);
  
    return () => clearTimeout(timer);
  }, [searchTerm]);

  useEffect(() => {
    if (triggerSearch && isSearching) {
      const fetchSearchBlogsData = async () => {
        try {
          const response = await axios.post(`${ServerURL}/search-service`, {
            searchString: searchTerm,
            page,
          });
          setBlogsList(response.data.data);
          setTotalPage(response.data.pagination.totalPages);
          setTriggerSearch(false);
        } catch (error) {
          console.error("Error fetching search results:", error);
        } finally {
          setIsSearchPending(false)
        }
      };
      setIsSearchPending(true)
      fetchSearchBlogsData();
    } else if (!isTyping && searchTerm === "") {
      const fetchData = async () => {
        try {
          let searchText = "";

          if (filterValue.trim() !== "") {
            const searchTerms = filterValue.trim().split(" ");
            searchText = searchTerms.join(" ");
          }

          const apiUrl = `${ServerURL}/landingPage/get-all-blogs`;

          const res = await axios.get(apiUrl, {
            params: {
              searchText: searchText,
              page: page,
            },
            limit: 10,
            depth: 2,
          });
          setTotalPage(res.data.totalPages);
          setBlogsList(res.data.docs);
        } catch (error) {
          console.error("Error fetching data:", error);
        }finally {
          setIsInitialPageRender(false);
        }
      };
      setIsInitialPageRender(true);
      fetchData();
      const currentURL = new URL(window.location.href);
      currentURL.searchParams.delete('search');
      window.history.pushState({}, '', currentURL.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue, isSearching, page, triggerSearch, searchTerm]);

  useEffect(() => {
    if (!isSearching) {
      const fetchData = async () => {
        try {
          const preRenderUrl = `${ServerURL}/landingPage/get-blogs-for-prerender`;

          const res = await axios.get(preRenderUrl);

          setPrerenderBlogsList(res.data.docs);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }
  }, [isSearching]);

  useEffect(() => {
    setBlogsData(
      navigator.userAgent === "ReactSnap" ? prerenderBlogsList : blogsList
    );
  }, [prerenderBlogsList, blogsList]);

  const renderBlogItem = (item) => {
    // Extract the publication date from the item.data array
    const dateItem = item?.data?.find((dataItem) =>
      dataItem.textvalue.startsWith("Published")
    );
    const publicationDate = dateItem
      ? dateItem.textvalue.replace("Published ", "").split(".")[0]
      : "";

    if (isSearchPending) {
      return (
        <>
          {[...Array(3)].map((_, index) => (
            <div key={index} className="row blogs_left_main">
              <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 pad0">
                <Skeleton height={200} width={"100%"} />
              </div>
              <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 blogs_space">
                <Skeleton height={30} width={"60%"} />
                <Skeleton
                  height={20}
                  width={"80%"}
                  style={{ marginTop: "10px" }}
                />
                <Skeleton
                  height={20}
                  width={"50%"}
                  style={{ marginTop: "10px" }}
                />
                <div className="d-flex blogs_sub_date_text">
                  <Skeleton
                    height={15}
                    width={"30%"}
                    style={{ marginTop: "10px" }}
                  />
                </div>
              </div>
            </div>
          ))}
        </>
      );
    } else {
      return (
        <div className="row blogs_left_main" key={item?.pageTitle}>
          <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 pad0">
            <div className="blogsmain_lft_pad_lft0 ">
              <a className="app_dev_tab" href={`/blogs/${item?.pageTitle}/`}>
                <LazyLoadImage
                  className="img-fluid"
                  src={item?.thumbNail}
                  alt={`blog-thumbNail-${item?.thumbNail}`}
                />
              </a>
            </div>
          </div>
          <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 blogs_space">
            <a href={`/blogs/${item?.pageTitle}/`}>
              <h2>{item?.data[0]?.textvalue}</h2>
            </a>
            <p>
              {item?.tags?.find((tag) => tag?.label === "description")?.value}
            </p>
            <div className="d-flex blogs_sub_date_text">
              <div>
                <span>{publicationDate}</span>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const handleSearch = async () => {
    if (searchTerm.trim()) {
      setIsSearching(true);
      setCurrentPage(1);
      setTriggerSearch(true);
      const currentURL = new URL(window.location.href);
      currentURL.searchParams.set('search', searchTerm);
      window.history.pushState({}, '', currentURL.toString());
    } else {
      setIsSearching(false);
      setCurrentPage(1);
      setTriggerSearch(false);
      setSearchTerm("");
    }
  };

  return (
    <div>
      {isLoading ? (
        <div className="loader-container">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <div>
          <Helmet>
            <title>
              EnLume - Technology Insights and updates for Innovation and growth
            </title>
            <meta
              name="description"
              content="EnLume Blogs features technical and educational content on Cloud computing, Fintech, MVP, Mobile apps, Mobile payments, Website usability and so on."
            />
          </Helmet>
          <Helmet>
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=G-L50781CCXR`}
            ></script>
            <script>
              {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-L50781CCXR');
          `}
            </script>
          </Helmet>
          <div className="about_new_main">
            <div>
              <section
                id="nomore_blogs_id"
                className="banner_section aboutus_new_banner_section"            
              >
                <div>
                  <picture class="banner_section aboutus_new_banner_section">
                    <source
                      srcset="/media-images/Blogs-bannernew-1-375x176.webp"
                      media="(max-width: 376px)"
                    />
                    <source
                      srcset="/media-images/Blogs-bannernew-1-768x360.webp"
                      media="(max-width: 769px)"
                    />
                    <source
                      srcset="/media-images/Blogs-bannernew-1-1366x640.webp"
                      media="(max-width: 1367px)"
                    />
                    <img
                      class="img-fluid banner_section aboutus_new_banner_section"
                      src="/media-images/Blogs-bannernew-1-1736327348875.webp"
                      alt="banner_image"
                    />
                  </picture>
                </div>
                <div className="banner_main aboutus_new_banner">
                  <div className="container">
                    <div className="row w100">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <h1 className="aboutnew_ban_head">
                          Dive Into The Blogosphere: Stories, Insights, And More
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <section className="new_services_ban_btm_section aboutnew_ban_btm_txt_sec blogs_ban_btm_strip">
            <div className="aboutnew_ban_btm">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 col-md-12 aboutusnew_ban_btm_txt">
                    <p>
                      Step into the dynamic world of technology with our blog.
                      We’re your source for the latest tech trends, innovation,
                      and insights. Whether you’re an enthusiast, coder, or just
                      curious, we keep you informed and inspired. Explore
                      emerging tech trends with us and stay ahead in this
                      exciting era of innovation!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="blog_search_section">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="search-container">
                    <BootstrapForm.Control
                      type="text"
                      placeholder="Search blogs..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSearch();
                        }
                      }}
                      className="search-input"
                    />
                    <button className="search-icon" onClick={handleSearch}>
                      &#128269;
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="blogs_mainbody_gap">
            <section className="all_stories_select_section">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 col-md-8 col-sm-6">
                    <h1>{selectedCategory}</h1>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 d-flex all_stories_sub">
                    <div className="stories_drop1">
                      <div className="filter-container">
                        {isFilterListOpen ? (
                          <img />
                        ) : (
                          <LazyLoadImage
                            src={filterIcon}
                            alt="Filter Icon"
                            className="filter-icon"
                            onClick={toggleFilterList}
                          />
                        )}
                        {isFilterListOpen && (
                          <select
                            value={filterValue}
                            onChange={(e) => {
                              if (e.target.value === "") {
                                setFilterValue("");
                              } else {
                                setFilterValue(e.target.value);
                              }
                            }}
                          >
                            <option value="">All</option>
                            <option value="AI/ML">
                              AI/ML
                            </option>
                            <option value="Data">Data</option>
                            <option value="Cloud">Cloud</option>
                            <option value="Product">Product</option>
                            <option value="Industries">Industries</option>
                            <option value="Trending">Trending</option>
                            <option value="Others">Others</option>
                          </select>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* Mobile View */}
            <div ref={scrollToRef}>
              <section className="new_blog_thumbnail_main_div">
                <div className="container">
                  <div className="row new_blog_thumb_row">
                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 blog_lft_sec_main">
                      {isInitialPageRender ? (
                        // Show skeleton loader when loading
                        <>
                          {[...Array(3)].map((_, index) => (
                            <div key={index} className="row blogs_left_main">
                              <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 pad0">
                                <Skeleton height={200} width={"100%"} />
                              </div>
                              <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 blogs_space">
                                <Skeleton height={30} width={"60%"} />
                                <Skeleton
                                  height={20}
                                  width={"80%"}
                                  style={{ marginTop: "10px" }}
                                />
                                <Skeleton
                                  height={20}
                                  width={"50%"}
                                  style={{ marginTop: "10px" }}
                                />
                                <div className="d-flex blogs_sub_date_text">
                                  <Skeleton
                                    height={15}
                                    width={"30%"}
                                    style={{ marginTop: "10px" }}
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      ) : blogsData.length === 0 ? (
                        <div className="no-blogs-message">No blogs found</div>
                      ) : (
                        blogsData.map((blog) => renderBlogItem(blog))
                      )}
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 blogs_categories_rht">
                      <h2>Categories</h2>
                      <div className="categories_sub image-list-items">
                        <ul>
                          {categories?.map((item, index) => {
                            let icon = null;
                            switch (item?.value) {
                              case "AI/ML":
                                icon = aiMlIcon;
                                break;
                              case "Data":
                                icon = dataIcon;
                                break;
                              case "Cloud":
                                icon = cloudIcon;
                                break;
                              case "Product":
                                icon = productIcon;
                                break;
                              case "Industries":
                                icon = industriesIcon;
                                break;
                              case "Trending":
                                icon = trendingIcon;
                                break;
                              case "Others":
                                icon = othersIcon;
                                break;
                            }

                            const isParentChecked = checkedCategories.includes(
                              item.value
                            );
                            const allSubcategoriesChecked =
                              item.subcategories?.every((subcategory) =>
                                checkedSubCategories.includes(subcategory.value)
                              );

                            return (
                              <li key={index}>
                                <div
                                  className={`category-item ${
                                    isParentChecked ? "active" : ""
                                  }`}
                                  onClick={() =>
                                    handleCheckboxChange(
                                      item.value,
                                      !isParentChecked,
                                      !allSubcategoriesChecked
                                    )
                                  }
                                >
                                  <label
                                    className="categories_sub_label"
                                  >
                                    <span
                                      className={`${
                                        allSubcategoriesChecked
                                          ? "category-item-text"
                                          : "category-item-text"
                                      }`}
                                    >
                                      {item?.categoriesItem}
                                    </span>
                                    <img src={icon} alt="" />
                                  </label>
                                  <div
                                    className="expand-toggle"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      toggleSubcategories(item.value);
                                    }}
                                  >
                                    {isCategoryExpanded(item.value) ? (
                                      <FontAwesomeIcon icon={faChevronUp} />
                                    ) : (
                                      <FontAwesomeIcon icon={faChevronDown} />
                                    )}
                                  </div>
                                </div>
                                {isCategoryExpanded(item.value) &&
                                  item.subcategories && (
                                    <ul className="subcategory-list sub-items">
                                      {item.subcategories.map(
                                        (subcategory, subIndex) => (
                                          <li
                                            key={subIndex}
                                            className={`subcategory-item ${
                                              checkedSubCategories.includes(
                                                subcategory.value
                                              )
                                                ? "sub-item-active"
                                                : ""
                                            }`}
                                            onClick={() =>
                                              handleSubcategoryClick(
                                                subcategory,
                                                item.value
                                              )
                                            }
                                          >
                                            <label className="categories_sub_label">
                                              {subcategory.label}
                                            </label>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  )}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            {/* Pagination */}
            {blogsData && blogsData.length > 0 ? (
              <div className="blogs_pagination_main">
                <nav aria-label="Page navigation example">
                  <ul className="pagination">
                    <li className={`page-item ${page === 1 ? "disabled" : ""}`}>
                      <a
                        className="page-link"
                        onClick={prePage}
                        aria-label="Previous"
                      >
                        <span aria-hidden="true">&laquo;</span>
                        <span className="sr-only"></span>
                      </a>
                    </li>
                    {renderPagination().map((p, index) => (
                      <li
                        key={index}
                        className={`page-item ${p === page ? "active" : ""} ${
                          p === "..." ? "disabled" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => p !== "..." && changeCurrentPage(p)}
                        >
                          {p}
                        </button>
                      </li>
                    ))}
                    <li
                      className={`page-item ${
                        page === totalPage ? "disabled" : ""
                      }`}
                    >
                      <a
                        className="page-link"
                        onClick={nextPage}
                        aria-label="Next"
                      >
                        <span aria-hidden="true">&raquo;</span>
                        <span className="sr-only"></span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            ) : (
              <></>
            )}
          </div>

          <ConversationSection />
        </div>
      )}
    </div>
  );
};

export default Blogs;
